import Mock from "../mock";

const database = {
  information: {
    name: 'Gabriel Francato',
    aboutContent: "Software Engineer, obsessed in learning and really proactivity. I strongly believe that teamwork and good communication are the key to excellent teams and, consequently, successful projects.",
    age: "21 December 1997",
    phone: '+55 11 975491595',
    nationality: 'Brazilian and Italian',
    language: 'Portuguese and English',
    email: '',
    address: 'Sao Paulo - Brazil',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/gabriel-francato/',
      dribbble: '',
      github: 'https://github.com/gabrielvf1',
      whatsapp: 'https://api.whatsapp.com/send?phone=5511975491595&text=gabriel%20francato',
      telegram: 'https://t.me/gabrielfrancato'
    },
    brandImage: '/images/profile_photo.jpg',
    aboutImage: '/images/gabriel-about-me.jpeg',
    aboutImageLg: '/images/gabriel-about-me.jpeg',
    cvfile: '/files/Gabriel_Francato_Resume_Web.pdf'
  },
  services: [
    {
      title: "Back end Development",
      icon: 'code',
      details: "I really like develpment api`s with complex logic and the most important thing is build something that can help other people to solve their problems."
    },
    {
      title: "Front end Development",
      icon: 'code',
      details: "I had some experience with UI/UX but something that i can really do is develop some screens and funcionalities using React."
    },
    {
      title: "Python Scripting",
      icon: 'code',
      details: "I really like python coding and a lot of my free time I am running some webscraping or building some automations with python language."
    }
  ],
  reviews: [
    // {
    //   id: 1,
    //   content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
    //   author: {
    //     name: 'Burdette Turner',
    //     designation: 'Web Developer, Abc Company'
    //   }
    // },
    // {
    //   id: 2,
    //   content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
    //   author: {
    //     name: 'Susan Yost',
    //     designation: 'Client'
    //   }
    // },
    // {
    //   id: 3,
    //   content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    //   author: {
    //     name: 'Irving Feeney',
    //     designation: 'Fiverr Client'
    //   }
    // }
  ],
  skills: [
    {
      title: "Python Coding",
      value: 100
    },
    {
      title: "ReactJS",
      value: 100
    },
    {
      title: "Elixir",
      value: 100
    },
    {
      title: "NodeJS",
      value: 70
    },
    {
      title: ".Net (C#)",
      value: 40
    },
    {
      title: "MYSQL",
      value: 80
    },
    {
      title: "Cloud Computing",
      value: 80
    },
    {
      title: "GitHub",
      value: 100
    },
    {
      title: "JavaScript",
      value: 100
    },
    {
      title: "Linux",
      value: 80
    },
    {
      title: "Django",
      value: 60
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Real Time Event Driven System",
      subtitle: "College Final Engeenering Project.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    }
    // {
    //   id: 2,
    //   title: "Coffee Mug",
    //   subtitle: "Awesome coffee mug design.",
    //   imageUrl: "/images/portfolio-image-4.jpg",
    //   largeImageUrl: [
    //     "/images/portfolio-image-4-lg.jpg",
    //     "/images/portfolio-image-4-lg2.jpg"
    //   ],
    //   url: 'https://facebook.com'
    // },
    // {
    //   id: 3,
    //   title: "Tea & Coffee Mug",
    //   subtitle: "Beautiful mug with logo.",
    //   imageUrl: "/images/portfolio-image-2.jpg",
    //   url: 'https://pinterest.com'
    // },
    // {
    //   id: 4,
    //   title: "Pen Holder",
    //   subtitle: "A pen holder with beautiful design.",
    //   imageUrl: "/images/portfolio-image-3.jpg",
    //   largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
    //   url: '#'
    // },
    // {
    //   id: 5,
    //   title: "Mug",
    //   subtitle: "Mug with awesome style",
    //   imageUrl: "/images/portfolio-image-5.jpg",
    //   largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    // },
    // {
    //   id: 6,
    //   title: "Pendrive",
    //   subtitle: "Free pendrive mockup design.",
    //   imageUrl: "/images/portfolio-image-6.jpg",
    //   largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    // },
    // {
    //   id: 7,
    //   title: "Beautiful Pendrive",
    //   subtitle: "Pendrive with great design & flexible.",
    //   imageUrl: "/images/portfolio-image-7.jpg",
    //   largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
    //   url: 'https://twitter.com'
    // },
    // {
    //   id: 8,
    //   title: "Sticker",
    //   subtitle: "Clip sticker mockup design.",
    //   imageUrl: "/images/portfolio-image-8.jpg",
    //   largeImageUrl: ["/images/portfolio-image-8-lg.jpg"]
    // },
    // {
    //   id: 9,
    //   title: "Packet",
    //   subtitle: "Beautiful packet & product design.",
    //   imageUrl: "/images/portfolio-image-9.jpg",
    //   largeImageUrl: ["/images/portfolio-image-9-lg.jpg"]
    // },
    // {
    //   id: 10,
    //   title: "Pen Holder",
    //   subtitle: "A pen holder with beautiful design.",
    //   imageUrl: "/images/portfolio-image-3.jpg",
    //   largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
    //   url: '#'
    // },
    // {
    //   id: 11,
    //   title: "Coffee Mug",
    //   subtitle: "Awesome coffee mug design.",
    //   imageUrl: "/images/portfolio-image-4.jpg",
    //   largeImageUrl: [
    //     "/images/portfolio-image-4-lg.jpg",
    //     "/images/portfolio-image-4-lg2.jpg"
    //   ],
    //   url: 'https://facebook.com'
    // },
    // {
    //   id: 12,
    //   title: "Tea & Coffee Mug",
    //   subtitle: "Beautiful mug with logo.",
    //   imageUrl: "/images/portfolio-image-2.jpg",
    //   url: 'https://pinterest.com'
    // },
    // {
    //   id: 13,
    //   title: "T-shirt Mockup",
    //   subtitle: "A beautiful t-shirt mockup.",
    //   imageUrl: "/images/portfolio-image-1.jpg",
    //   largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
    //   url: 'https://dribbble.com'
    // },
    // {
    //   id: 14,
    //   title: "Mug",
    //   subtitle: "Mug with awesome style",
    //   imageUrl: "/images/portfolio-image-5.jpg",
    //   largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    // },
    // {
    //   id: 15,
    //   title: "Pendrive",
    //   subtitle: "Free pendrive mockup design.",
    //   imageUrl: "/images/portfolio-image-6.jpg",
    //   largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    // },
    // {
    //   id: 16,
    //   title: "Beautiful Pendrive",
    //   subtitle: "Pendrive with great design & flexible.",
    //   imageUrl: "/images/portfolio-image-7.jpg",
    //   largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
    //   url: 'https://twitter.com'
    // },
    // {
    //   id: 17,
    //   title: "Sticker",
    //   subtitle: "Clip sticker mockup design.",
    //   imageUrl: "/images/portfolio-image-8.jpg",
    //   largeImageUrl: ["/images/portfolio-image-8-lg.jpg"]
    // },
    // {
    //   id: 18,
    //   title: "Packet",
    //   subtitle: "Beautiful packet & product design.",
    //   imageUrl: "/images/portfolio-image-9.jpg",
    //   largeImageUrl: ["/images/portfolio-image-9-lg.jpg"]
    // },
    // {
    //   id: 19,
    //   title: "T-shirt Mockup",
    //   subtitle: "A beautiful t-shirt mockup.",
    //   imageUrl: "/images/portfolio-image-1.jpg",
    //   largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
    //   url: 'https://dribbble.com'
    // },
    // {
    //   id: 20,
    //   title: "Coffee Mug",
    //   subtitle: "Awesome coffee mug design.",
    //   imageUrl: "/images/portfolio-image-4.jpg",
    //   largeImageUrl: [
    //     "/images/portfolio-image-4-lg.jpg",
    //     "/images/portfolio-image-4-lg2.jpg"
    //   ],
    //   url: 'https://facebook.com'
    // },
    // {
    //   id: 21,
    //   title: "Tea & Coffee Mug",
    //   subtitle: "Beautiful mug with logo.",
    //   imageUrl: "/images/portfolio-image-2.jpg",
    //   url: 'https://pinterest.com'
    // },
    // {
    //   id: 22,
    //   title: "Pen Holder",
    //   subtitle: "A pen holder with beautiful design.",
    //   imageUrl: "/images/portfolio-image-3.jpg",
    //   largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
    //   url: '#'
    // },
    // {
    //   id: 23,
    //   title: "Mug",
    //   subtitle: "Mug with awesome style",
    //   imageUrl: "/images/portfolio-image-5.jpg",
    //   largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    // }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2022 - Present",
        position: "Web Developer (Full Stack)",
        company: "BTG Pactual Investment Bank",
        details: "Member of the team in charge of the bank's platform for business customers. Specifically dealing with squad monitoring and development features to the area's core systems. Focused on the application's backend and my first time working with functional language (elixir). Also develop in.Net and occasionally develop some frontend features in React with Typescrpit. The platform has over 30 thousand users and more than 300 registrations per day. Also, one of the systems for which I am responsible is the authentificator for the entire Ecosystems, which receives over 200 requests per second during the day. AWS and Azure DevOps provide a very robust infrastructure. In 2022, the platform received the Global Finance Best SME Bank Award in the categories Global, Brazil, and Latin America."
      },
      {
        id: 2,
        year: "2020 - 2022",
        position: "Web Developer (Full Stack)",
        company: "Sum Solutions",
        details: "I joined the startup at an early stage, when it was just the four partners and me, with a lot to build. The startup's idea was to create a tool to assist investment managers in controlling their stock positions because the majority of people who work in the financial market nowadays use Excels, which are frequently incapable of working with real-time data and complex calculations and data. The four partners had worked in a large bank called Itau and had developed an intern tool to make that, they realized the success of the software and decided to leave the bank and develop a more complex software to start selling it. This job gave me the freedom to make my own decisions while also exposing me to a wide range of UI/UX and other business areas. Aside from that, I learned a lot of React and Django (my favorite code language is Python), and I was really focused on developing front end features. It was a really nice opportunity and a place where I could grow as a professional and, most importantly, as a person."
      },
      {
        id: 3,
        year: "2019 - 2019",
        position: "Information Security, Summer Intern",
        company: "Safra Bank",
        details: "While in college, I applied for a job that was extremely competitive, with over 3000 people competing for only 30 job openings. It was a brief experience because it occurred during the summer vacation, but it was a very good first contact working in a large corporation and a very pleasant experience. In this job, I used a lot of MySql and Python, and my goal was to monitor and create dashboards to show the area managers how the bank fraud issue was progressing. So, using their database, I needed to run a lot of queries to gather data and generate insights into potential areas for improvement. It was also an excellent opportunity to make a presentation to people in key positions within the company."
      },
      {
        id: 3,
        year: "2017 - 2017",
        position: "Web Developer, Summer Intern",
        company: "Gal (Lapag)",
        details: "It was my first time working on a project. This was an early stage startup founded by a group of friends from my college. They reunited some students to help them develop an idea they had while in college, which was to simplify the financials of a beauty salon. I was in my first year of college, so I didn't have many technical skills to offer, but some older students taught me a lot. The most important lesson I learned during this job was agile development. During our days, we used a lot of agile development concepts, such as stand-up meetings with chairs to be a really quick meeting, poker planning, and other agile development techniques. In 2021 this startup received a contribution from investors in the amount of 50 million reais."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2017 - 2021",
        graduation: "Bachelor of Engineering, Computer Engineering",
        university: "Insper Learning Institution",
        details: "The course has a very different and modern didactic, with many practical activities the motto is: learn to learn. In addition, the campus infrastructure is the best in Brazil. The course was greatly inspired by the faculty of Olin College in Needham, Massachusetts (USA), even forming a partnership with them and exchanging a lot of knowledge and teaching methodologies. More recently, the faculty also announced a partnership with the University of Illinois for engineering courses."
      },
      {
        id: 2,
        year: "2016 - 2017",
        graduation: "High School, Secondary School",
        university: "Carson Graham Secondary School (North Vancouver)",
        details: "I did an Exchange at the end of my high school to North Vancouver in order to improve my English and have an experience outside the country on my own. In addition to getting to know a new culture and living in a country that I consider an example for the rest of the world. After the exchange, I can say that I am much more confident about my English because I have lived 6 months with classes in English and several colleagues from different countries that I only communicate in English with them. This helped a lot in my area of expertise, which is technology."
      }
    ]
  },
  blogs: [
    // {
    //   id: 1,
    //   title: 'Markdown & Html supported blog.',
    //   featuredImage: '/images/blog-image-1.jpg',
    //   filesource: '../../blog/markdown-html-supported-blog.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 2,
    //   title: 'Installing NodeJS on your device.',
    //   featuredImage: '/images/blog-image-2.jpg',
    //   filesource: '../../blog/installing-nodejs-on-your-device.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 3,
    //   title: 'UI/UX design starter with Adobe XD.',
    //   featuredImage: '/images/blog-image-3.jpg',
    //   filesource: '../../blog/uiux-design-starter-with-adobe-xd.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 4,
    //   title: 'Boost your post for increasing sales.',
    //   featuredImage: '/images/blog-image-4.jpg',
    //   filesource: '../../blog/boost-your-post-for-increasing-sales.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 5,
    //   title: 'Difference between GatsbyJS & NextJS.',
    //   featuredImage: '/images/blog-image-5.jpg',
    //   filesource: '../../blog/difference-between-gatsbyjs-and-nextjs.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 6,
    //   title: 'How to choose javascript framework for project.',
    //   featuredImage: '/images/blog-image-6.jpg',
    //   filesource: '../../blog/how-to-choose-javascript-framework-for-project.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 7,
    //   title: 'Web automation with python language.',
    //   featuredImage: '/images/blog-image-7.jpg',
    //   filesource: '../../blog/web-automation-with-python-language.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 8,
    //   title: 'Time to use latest technology for creating a website.',
    //   featuredImage: '/images/blog-image-8.jpg',
    //   filesource: '../../blog/time-to-use-latest-technology-for-creating-a-website.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 9,
    //   title: 'Think out of the box.',
    //   featuredImage: '/images/blog-image-9.jpg',
    //   filesource: '../../blog/think-out-of-the-box.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 10,
    //   title: 'Trending designs in 2020.',
    //   featuredImage: '/images/blog-image-1.jpg',
    //   filesource: '../../blog/trending-designs-in-2020.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 11,
    //   title: 'How to get 10k instagram followers?',
    //   featuredImage: '/images/blog-image-2.jpg',
    //   filesource: '../../blog/how-to-get-10k-instagram-followers.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 12,
    //   title: 'What NodeJS can do?',
    //   featuredImage: '/images/blog-image-3.jpg',
    //   filesource: '../../blog/what-nodejs-can-do.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 13,
    //   title: 'Futures of javascript.',
    //   featuredImage: '/images/blog-image-4.jpg',
    //   filesource: '../../blog/future-of-javascript.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 14,
    //   title: 'Popular javascript library in 2020.',
    //   featuredImage: '/images/blog-image-5.jpg',
    //   filesource: '../../blog/popular-javascript-library-in-2020.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 15,
    //   title: 'Promrammers must read books.',
    //   featuredImage: '/images/blog-image-6.jpg',
    //   filesource: '../../blog/programmers-must-read-books.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // }
  ],
  contactInfo: {
    phoneNumbers: ['+55 11 97549-1595'],
    emailAddress: ['vf_gabriel@yahoo.com.br', 'gabrielvf@al.insper.edu.br'],
    address: "São Paulo - Brazil"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});